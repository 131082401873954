var disableAutoSlide = false;

function bindLeftRight(classname, functionname)
{
	var objects = document.getElementsByClassName(classname);
	for (i = 0; i < objects.length; i++) 
	{
        objects[i].addEventListener( 'click', functionname);
	}
}

function modifyCurrentSlider(modification, manually)
{
	var inputs = document.getElementsByTagName("input");
	var currentObject = document.querySelector('input[type = "radio"]:checked');
	var current = 0;
    if(manually) 
    {
        disableAutoSlide = true;
    }
	for (i = 0; i < inputs.length; i++) 
	{
        if(inputs[i] == currentObject)
        {
        	current = i;
        }
    }
    current += modification;
    if(current == -1)
    {
    	current = inputs.length -1;
    }
    inputs[current % inputs.length].checked = true;
}

document.addEventListener('play', function(e){
    disableAutoSlide = true;
    var audios = document.getElementsByTagName('audio');
    for(var i = 0, len = audios.length; i < len;i++){
        if(audios[i] != e.target){
            audios[i].pause();
        }
    }
}, true);

document.addEventListener('play', function(e){
    _paq.push(['trackEvent', e.type, e.target.currentSrc, e.target.currentTime]);
}, true);

document.addEventListener('pause', function(e){
    _paq.push(['trackEvent', e.type, e.target.currentSrc, e.target.currentTime]);
}, true);

document.addEventListener('ended', function(e){
    _paq.push(['trackEvent', e.type, e.target.currentSrc, e.target.currentTime]);
}, true);

window.onload = function()
{
    bindLeftRight("left",  function() { modifyCurrentSlider(-1, true); });
    bindLeftRight("right", function() { modifyCurrentSlider(1,  true);  });
    setInterval(function()
        { 
            if(!disableAutoSlide)
            {
                modifyCurrentSlider(1,  false);
            }
        }
        , 3000
    );
}